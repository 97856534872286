<template>
  <div class="pressCenter">
    <Nav :routerValue="routerValue"></Nav>
    <div class="contactUs wow animate__fadeInDown">
      <div class="topimg ">
        <img src="../../assets/img/newscenter.png" alt="">
      </div>
      <div class="home">
        <i class="el-icon-s-home"></i>
        <div>首页</div>
        <i class="el-icon-arrow-right"></i>
        <div>新闻中心</div>
        <i class="el-icon-arrow-right"></i>
        <div>新闻详情</div>
      </div>
    </div>
    <div class="newList">
      <div class="newList_left">
        <div class="title">{{ newObj.title }}</div>
        <div class="time">
          <img src="../../assets/img/qiye.png" alt="">
          <div>{{ newObj.newsColumn }}</div>
          <img src="../../assets/img/riqi.png" alt="">
          <div>{{ newObj.updateTime }}</div>
        </div>
        <el-divider></el-divider>
        <div class="convention deep" v-html="newObj.content" @click="handleClickOnImage">
        </div>
        <!--<div v-if="isPreviewVisible" class="image-preview-modal">-->
        <!--  -->
        <!--  <img :src="previewImageSrc" @click="closePreview"/>-->
        <!--  <button @click="closePreview">关闭</button>-->
        <!--</div>-->
        <el-image-viewer
            v-if="isPreviewVisible"
            :on-close="closeViewer"
            :url-list="srcList"
        ></el-image-viewer>
      </div>
      <div class="newList_right wow animate__fadeInDown">
        <div class="recommend"><span>推荐新闻</span> <span>RECOMMENDED NEWS</span></div>
        <div class="list" v-for="item in list" :key="item.id" v-if="list.length > 0" @click="lookInfo(item.id)">
          <div class="newsImg_left">
            <img class="newsImg" :src="item.cover" alt="">
          </div>
          <div class="convention">
            <div class="title">
              <img src="../../assets/img/qiye.png" alt="">
              <div>{{ item.newsColumn }}</div>
            </div>
            <div class="shareholder">{{ item.title }}</div>
            <div class="description">
              {{ item.seoAbstract }}
            </div>
            <div class="time">
              <div class="date">
                <img src="../../assets/img/riqi.png" alt="">
                <div>{{ item.createTime }}</div>
              </div>
              <div class="info">
                <div><span class="text">查看详情<i class="el-icon-arrow-right"></i></span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <el-button icon="el-icon-arrow-left" @click="perv()" :disabled="btndisabled1">上一篇</el-button>
          <el-button @click="next()" :disabled="btndisabled">下一篇 <i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>
    <div>
      <el-backtop :bottom="450" :visibility-height="1000">
        <div style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
              }">
          UP
        </div>
      </el-backtop>
    </div>
    <btm></btm>
  </div>
</template>

<script>
import Nav from '@/components/Navigation.vue'
import btm from '@/components/bottom.vue'
import {newsId, news} from "@/api/ly"
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  "name": 'pressCenter',
  components: {
    Nav,
    btm,
    ElImageViewer
  },
  data() {
    return {
      routerValue: 'pressCenter',
      newObj: {},
      list: [],
      total: 0,
      btndisabled1: false,
      btndisabled: false,
      newID: undefined,
      newindex: undefined,
      newsList: {
        pageNum: 1,
        pageSize: 2,
        showLocation: 2
      },
      isPreviewVisible: false,
      previewImageSrc: '',
      srcList:[]
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //     new WOW({ live: true }).init();

    // })
  },

  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#EEF2F6'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  },
  created() {
    this.newID = this.$route.params.id;
    this.init();
    this.initNews(this.newID);
  },
  methods: {
    lookInfo(id) {
      this.initNews(id);
    },
    perv() {
      // if (this.newindex == 0) {
      //     this.btndisabled1 = true;
      // } else {
      //     this.newindex--;
      //     this.newObj = this.list[this.newindex];
      //     this.btndisabled = false;
      // }
      this.initNews(this.newObj.lastId);
    },
    next() {
      this.initNews(this.newObj.nextId);
      // this.$router.push({name: 'newsDetails', query: {  id: this.newObj.lastId } })
      // if (this.list.length - this.newindex == 1) {
      //     this.btndisabled = true;
      // } else {
      //     this.newindex++;
      //     this.newObj = this.list[this.newindex];
      //     this.btndisabled1 = false;
      // }
    },
    init() {
      news(this.newsList).then(res => {
        if (res.code == 200) {
          this.list = res.rows;
        }
      })
    },
    initNews(id) {
      newsId(id).then(res => {
        if (res.code == 200) {

          this.newObj = res.data;
          // this.processContent(this.newObj.content)
          if (!this.newObj.nextId) {
            this.btndisabled = true;
            this.btndisabled1 = false;
          }
          ;
          if (!this.newObj.lastId) {
            this.btndisabled1 = true;
            this.btndisabled = false;
          }
          ;
        }
      });
    },
    processContent(htmlString) {
      // 定义一个正则表达式来匹配 <img> 标签
      const imgRegex = /<img\s+([^>]*?)\s*\/?>/gi;

      // 使用 replace 方法进行替换
      const replacedString = htmlString.replace(imgRegex, (match, attributes) => {
        // 提取 src 和 alt 属性
        const srcMatch = attributes.match(/src="([^"]*)"/i);
        const altMatch = attributes.match(/alt="([^"]*)"/i);

        const src = srcMatch ? srcMatch[1] : '';
        const alt = altMatch ? altMatch[1] : '';

        // 返回 el-image 标签
        return `<el-image src="${src}" alt="${alt}"></el-image>`;
      });
      console.log(replacedString)
      return replacedString;
      // let abc =this.newObj.content.replace(/<img\s+src="([^"]+)"\s+alt="([^"]*)"\s*\/?>/g, (match, src, alt) => {
      //   return `<el-image src="${src}" alt="${alt}"  preview-src-list="[${src}]"></el-image>`;
      // });
      // // preview-src-list="[${Object.values(src).filter(src => src.startsWith('http')).map(src => `"${src}"`).join(', ')}]""
      // console.log(abc)
    },
    handleClickOnImage(event) {
      if (event.target.localName === 'img') {
        console.log(event.target.src)
        // this.previewImageSrc = event.target.src;
        this.srcList =[ event.target.src];
        const m = (e) => { e.preventDefault() };
        document.body.style.overflow = 'hidden';
        document.addEventListener("touchmove", m, false); // 禁止页面滑动
        this.isPreviewVisible = true;
      }
    },
    // 查看图片
    closeViewer() {
      const m = (e) => { e.preventDefault() };
      document.body.style.overflow = 'auto';
      document.removeEventListener("touchmove", m, true);
      this.isPreviewVisible = false;
      this.srcList = []
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .deep {
  word-wrap: break-word;

  img {
    //width: 1000px;
    //height: 570px;
    //text-align: center;
    margin: 10px auto;
    display: block;
  }
}

.pressCenter {
  width: 100%;

  .contactUs {
    width: 100%;
    background: #ffffff;

    .home {
      padding: 34px 0;
      width: 1500px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      color: #1A1A1A;
      font-size: 14px;
      font-family: Source Han Sans CN;

      :last-child {
        color: #13A3F0;
      }
    }
  }

  .newList {
    width: 1500px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    //max-height: 1600px;

    .newList_left {
      width: 65%;
      padding: 20px 30px;
      background-color: #ffffff;

      .title {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #1A1A1A;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
      }

      .time {
        margin-top: 30px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #808080;
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 13px;
        }

        :nth-child(2n-1) {
          margin-right: 10px;
        }

        :nth-child(2n) {
          margin-right: 30px;

        }
      }

      .convention {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7D7D7D;
        line-height: 30px;
      }

      .news {
        margin: 40px 0;
        width: 100%;
        height: 570px;
      }
    }

    .newList_right {
      width: 27%;

      .recommend {
        width: 100%;
        background-color: #ffffff;
        padding: 20px 30px;

        :first-child {
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }

        :last-child {
          margin-left: 10px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #13A3F0;
        }
      }

      .list {
        width: 465px;
        height: 500px;
        background-color: #ffffff;
        margin-top: 50px;
        cursor: pointer;

        .newsImg_left {
          width: 465px;
          height: 270px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .convention {
          padding: 30px;

          .title {
            width: 100%;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            :last-child {
              margin-left: 10px;
            }
          }

          .shareholder {
            width: 100%;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            margin: 5px 0 12px;
          }

          .description {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 405px;
            height: 57px;
          }

          .time {
            margin-top: 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #808080;

            .date {
              width: 60%;
              display: flex;
              align-items: center;

              :last-child {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .btn {
        width: 480px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}
.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.image-preview-modal img {
  max-width: 90%;
  max-height: 90%;
}

.image-preview-modal button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
</style>
